import React from 'react';
import Layout from 'components/layout';
import WolfmixLogo from 'components/wolfmixlogo';

const Wolfmix = () => (
  <Layout>
    <WolfmixLogo></WolfmixLogo>
  </Layout>
);

export default Wolfmix;
