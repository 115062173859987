import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${MEDIA.TABLET`
    display: block;
    margin: 0 1em 1em 1em;
  `};
`;

export const CenterImage = styled.div`
  padding: 5px;
  max-width: 100%;
  margin: 10px;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: black;
  }
`;
