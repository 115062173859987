import React, { useEffect, useRef, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import ReactPlayer from 'react-player';
import shirtLogo from '../../../content/images/shirt_design.png';
import casette from '../../../content/videos/casette_compressed.mp4';
import { CenterImage, Container, GlobalStyle } from './wolfmixlogo.css.js';

const WolfmixLogo = () => {
  let [shouldPlay, updatePlayState] = useState(true);
  const video = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      document.body.style.backgroundColor = 'black';
    }, 100);
  });

  const toggleMix = function() {
    updatePlayState(!shouldPlay);
    !video.current.paused ? video.current.pause() : video.current.play();
  };
  return (
    <Container>
      <GlobalStyle />
      <BrowserView>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <iframe
            title="spotify"
            src="https://open.spotify.com/embed/playlist/2JSMmPkLkf6M143eKhL5It?theme=0"
            width="100%"
            height="400"
            frameBorder="0"
            allowTransparency="true"
            allow="encrypted-media"
            data-dashlane-frameid="557"
          ></iframe>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CenterImage>
            <video
              muted
              ref={video}
              width="640"
              height="480"
              loop
              // onClick={toggleMix}
              autoPlay
            >
              <source src={casette} type="video/mp4" />
              <track kind="captions" />
            </video>
          </CenterImage>
          {/* <span style={{ fontSize: '90%', marginBottom: '2em' }}>
            Fashion C&apos;est La Latest Attraction w/ Josua Zajons
          </span> */}
          <img
            src={shirtLogo}
            alt="Wolfmich T-Shirt"
            style={{ maxWidth: '100px' }}
          />
        </div>
        {/* <ReactPlayer
          style={{ opacity: '0', pointerEvents: 'none' }}
          playing={shouldPlay}
          url="https://soundcloud.com/wolfmixmusic/sets/wolfmix"
        /> */}
      </BrowserView>
      <MobileView>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CenterImage>
            {/* <ReactPlayer
                style={{ position: 'absolute', top: '0', left: '0' }}
                width="100%"
                height="100%"
                playing={shouldPlay}
                url="https://soundcloud.com/wolfmixmusic/sets/wolfmix"
              /> */}
            <div style={{ margin: '100px auto 100px auto', maxWidth: '600px' }}>
              <iframe
                title="spotify"
                src="https://open.spotify.com/embed/playlist/2JSMmPkLkf6M143eKhL5It?theme=0"
                width="100%"
                height="400"
                frameBorder="0"
                allowTransparency="true"
                allow="encrypted-media"
                data-dashlane-frameid="557"
              ></iframe>
            </div>
            <video muted ref={video} width="640" height="480" loop autoPlay>
              <source src={casette} type="video/mp4" />
              <track kind="captions" />
            </video>
          </CenterImage>
          <img
            src={shirtLogo}
            alt="Wolfmich T-Shirt"
            style={{ maxWidth: '100px' }}
          />
        </div>
      </MobileView>
    </Container>
  );
};

export default WolfmixLogo;
